
<template>
  <vue-final-modal @before-open="get_data" v-slot="{ close }" v-bind="$attrs" classes="modal-container"
    content-class="modal-content" :esc-to-close="true" :click-to-close="true" :styles="{
      width:'100%',
      margin: '0 auto'
    }" :content-style="{
      width: 'auto',
      background: '#fff', 
      marginTop: '25px' 
    }">
    <p class="is-flex bg-gradient-to-r from-slate-50 to-slate-300"> 
      <span class="subtitle m-2"> Garancia</span>
      <button  @click="close" class="delete ml-auto m-3"></button>
      <!-- <button class="modal__close ml-auto mr-2" @click="close">
        <i class="fas fa-times"></i>
      </button> -->
    </p>
    <div class="modal__content p-3">
      <div class="has-background-white mt-1" >
        <div v-if="print_documents.length > 0" :class="[print_documents.length == 1 ? 'grid-cols-1' : `grid-cols-${print_documents.length}`]" class="grid gap-2 auto-cols-fr">
            <div v-for="(doc, index) in print_documents" :key="index" class="box py-3 bg-white h-full" >
                <div  class="is-flex is-flex-direction-column h-full">
                    <!-- html part -->
                    <div :id="'id_' + doc.guid" 
                      v-html="usePrintStore().get_print_html(doc.content, process)"
                      class="border mt-1 is-flex-grow-1 p-1" ></div>


                    <!-- print buttons -->
                    <div class="flex gap-1 justify-end">
                      
                      <div class="my-2" v-for="(p, index) in products" :key="index" >
                          <a @click.prevent="print_document(doc, index)" href="" class="button is-success is-small is-block w-100">
                            <i class="fa fa-print"></i>
                            {{p.name}}
                          </a>
                      </div>
                    </div>
                </div>
                <!-- // html content... -->
            </div>
        </div>
      </div>
    </div>
  </vue-final-modal>
</template>

<script setup>
    import { useProcessStore } from "@/stores/process";
    import { usePrintStore } from "@/stores/print";
</script>

<script>
import { VueFinalModal } from 'vue-final-modal'
import Api from "@/services/Api";

export default {
  setup() {
    return {}
  },
  data() {
    return {
        step: {},
        print_documents: { empty: true }, 
        products: []
    }
  },
  methods: {
    async get_data(event) {
      this.process = event.ref.params.value.process
        // api /print/step/documents this.step
        await Api(true).post('/print/document/warranties', {process: this.process})
        .then(res => {
            this.print_documents = res.data[1]
            this.products = res.data[0]
        })
    },
    print_document(doc, index){
      if(index >= 0) {
        var document_data = useProcessStore().process.document_data 
        document_data['items'] = [this.products[index]];
    
        var content = usePrintStore().get_print_html(doc.content, {
          ...useProcessStore().process, 
          'document_data': document_data
        })
        usePrintStore().print_entity(content)
      }
    }
  },
  computed: {
    process() {
      var document_data = useProcessStore().process.document_data 
      document_data['items'] = this.products;
        return {
          ...useProcessStore().process, 
          'document_data': document_data
        }
    }
  },
  components: {
    VueFinalModal,
  },
}
</script>

<style>
.modal__title {
  margin: 1.6rem;
  font-size: 1.5rem;
  font-weight: 700;
}
.modal-content{
  width:80%!important;
}
</style>

